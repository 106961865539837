<template>
  <v-btn
    class="mr-4 rounded"
    height="36px"
    width="36px"
    outlined
    tile
    icon
    @click="closeEvent"
  >
    <v-icon size="24">
      <slot>
        mdi-arrow-left
      </slot>
    </v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'BackButton',
    props: {
      icon: {
        type: String,
        default: 'mdi-arrow-left',
      },
    },
    methods: {
      async closeEvent () {
        this.$emit('close-event')
      },
    },
  }
</script>

<style scoped>

</style>
