import { IKDataEntity } from 'metaflow-js'
import i18n from '@/i18n'

const entity = {
  name: {
    displayName: 'name',
    formConfig: {
      hint: i18n.t('NameInputHint'),
    },
  },
  lang: {},
  desc: {
    displayName: 'description',
    formConfig: {
      textArea: true,
      maxLength: 100,
      required: false,
    },
  },
}
export default IKDataEntity.ModelFactory(entity, {})
