import GlobalSettings from '@/GlobalSettings'
import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      requiredNew: false,
    },
  },
  name: {},
  lang: {},
}
const config = {
  load: async function (filter) {
    return (await hillo.get('CategoryType.php', {
      lang: GlobalSettings.getLang(),
      ...filter,
    }))
      .content.map(it => {
        if (it.id === 9) {
          it.name = '19%'
        } else if (it.id === 10) {
          it.name = '7%'
        }
        return it
      })
  },
}

export default IKDataEntity.ModelFactory(entity, config)
