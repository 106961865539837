import GlobalSettings from '@/GlobalSettings'
import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import Section from '../Section'
// import store from '../store'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    form: false,
    header: false,
  },
  name: {
    displayName: 'tableNumber',
  },
  sectionId: {
    displayName: 'sectionName',
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: Section.getList,
        itemText: 'name',
      },
    },
  },
  seatCount: {
    formConfig: {
      hint: 'the_max_number_of_reserved_customer_at_this_table', // 在本桌接受预定时,最多可以容纳多少个人
      default: 4,
    },
  },
}
const config = {
  add: function (item) {
    return hillo.postWithUploadFile('Tables.php?op=add', item, {
      showLoading: true,
    })
  },
  edit: function (item) {
    return hillo.postWithUploadFile('Tables.php?op=update', item)
  },
  remove: function (id) {
    return hillo.post('Tables.php?op=delete', { id: id })
  },
  load: async function (filter) {
    return (await hillo.get('Tables.php', {
      op: 'currentInfos',
      lang: GlobalSettings.getLang(),
      ...filter,
    })).content.filter(it => parseInt(it.sectionId) !== 6)
  },
}

export default IKDataEntity.ModelFactory(entity, config)
