import i18n from '../../i18n'

export default {
  list: [
    {
      id: 0,
      name: i18n.t('failed_to_print'),
    }, {
      id: 1,
      name: i18n.t('print_success'),
    }, {
      id: 2,
      name: i18n.t('validation_error'),
    }, {
      id: 3,
      name: i18n.t('non_standardished_bon_format'),
    }, {
      id: 4,
      name: i18n.t('print'),
    }, {
      id: 5,
      name: i18n.t('network_error'),
    },
    {
      id: 6,
      name: i18n.t('waiting_for_the_sunmi_printer_to_print'),
    },
  ],
  getList: function () {
    return this.list
  },
}
