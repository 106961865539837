export default {
  list: [
    {
      id: 1,
      name: 'Others',
    }, {
      id: 2,
      name: 'RechnungBon',
    }, {
      id: 3,
      name: 'Zbon',
    }, {
      id: 4,
      name: 'KitchenBon',
    },
  ],
  getList: function () {
    return this.list
  },
}
