export default {
  list: [
    {
      id: -11,
      color: 'brown lighten-5 black--text',
    }, {
      id: -10,
      color: 'deep-orange lighten-5',
    },
    {
      id: 0,
      color: 'error lighten-4 black--text',
    },
    {
      id: 1,
      color: 'primary lighten-4 black--text',
    }, {
      id: 2,
      color: 'success lighten-4 black--text',
    }, {
      id: 3,
      color: 'warning lighten-4 black--text',
    }, {
      id: 4,
      color: 'secondary lighten-4 black--text',
    }, {
      id: 5,
      color: 'accent lighten-4 black--text',
    }, {
      id: 6,
      color: 'tertiary lighten-4 black--text',
    }, {
      id: 7,
      color: 'info',
    }, {
      id: 8,
      color: 'pink',
    }, {
      id: 9,
      color: 'cyan',
    }, {
      id: 10,
      color: 'deep-purple lighten-4',
    }, {
      id: 11,
      color: 'lime',
    }, {
      id: 12,
      color: 'light-green',
    }, {
      id: 13,
      color: 'amber',
    }, {
      id: 14,
      color: 'yellow',
    }, {
      id: 15,
      color: 'deep-orange',
    }, {
      id: 16,
      color: 'blue lighten-4',
    }, {
      id: 17,
      color: 'blue-grey',
    }, {
      id: 18,
      color: 'yellow darken-4',
    }, {
      id: 19,
      color: 'brown lighten-5',
    }, {
      id: 20,
      color: 'deep-orange lighten-5',
    },
  ],
  getList: function () {
    return this.list
  },
}
