import { render, staticRenderFns } from "./BillDetailPage.vue?vue&type=template&id=1ccba482&scoped=true&"
import script from "./BillDetailPage.vue?vue&type=script&lang=js&"
export * from "./BillDetailPage.vue?vue&type=script&lang=js&"
import style0 from "./BillDetailPage.vue?vue&type=style&index=0&id=1ccba482&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ccba482",
  null
  
)

export default component.exports